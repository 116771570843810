import React, { useState } from "react";
import PropTypes from 'prop-types';
import Race from './Race.js'
import './RaceOnline.css';


export default function RaceOnline(props) {
  const [raceIndex, setRaceIndex] = useState(0);
  const [wasChanged, setWasChanged] = useState(false);
  const handleChange = ({ target }) => {
    // In this case it is more intuitive to use selectedIndex than
    // value. It is the array index anyhow.
    setRaceIndex(target.selectedIndex);
    setWasChanged(true);
  }

  if (!props.raceList.length) {
    return <br />;
  } else if (props.raceList.length === 1) {
    return <Race
      race={props.raceList[0]}
      wasChanged={wasChanged}
      />;
  } else {
    // The final case: There are several races to choose from
    return (
      <div>
        <h3>Navisport-Online</h3>
        <select className="dropdown" onChange={handleChange}>
          {props.raceList.slice(0, 3).map(race => 
            <option key={race.id}>
              {race.begin.slice(0, 10)} - {race.name}
            </option>
          )}
        </select>

        <Race 
          race={props.raceList[raceIndex]}
          wasChanged={wasChanged}
          />
      </div>
    );
  }
}

RaceOnline.propTypes = {
  raceList: PropTypes.array.isRequired,
}

