import { useState, useEffect } from "react";
import getAnalyzedResults from './getAnalyzedResults';

export default function useProcessedResults(matchNames, resultLongList, courseList, courseIndex, isDefault) {

  const [processedResults, setProcessedResults] = useState([]);

  useEffect(() => {
    console.log(`matchNames: ${matchNames.length}, results: ${resultLongList.length}, courseIndex: ${courseIndex}`);
    if (!resultLongList.length) {
      setProcessedResults([]);
    } else if (matchNames.length && courseList.length) {
      setProcessedResults(
        getAnalyzedResults(
          resultLongList,
          courseList[courseIndex],
          matchNames
        )
      );
    }
  }, [matchNames, resultLongList, courseList, courseIndex, isDefault]);

  return processedResults;
}
