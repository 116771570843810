// Calculates position stats for each result of a course
// by Riku 2020-11-21
//
import { LONGTIME, statusOk, statusManual } from './definitions';

export function calculateOverallPositions(results, nameOfPosition) {
  results.sort(compTime);
  addPositions(results, nameOfPosition, 'time');
}

export function calculateControlPositions(results, controls) {
  if (results.length) {
    let sortedResults = [...results] // separate array, but shares same objects

    for (let ctrlIndex = 0; ctrlIndex < controls.length; ctrlIndex++) {
      //
      // For each control STEP 1: prepare the two sorts
      sortedResults.forEach(result => {
        if (!statusManual(result)) {
          decorateWithControl(result, ctrlIndex);
        }
      });
      // For each control STEP 2: Sort and position calc cumTime.
      sortedResults.sort(compCumTime);
      addPositions(sortedResults, 'positionCumTime', 'compCumTime');
      //
      // For each control STEP 3: Sort and position calc legTime.
      sortedResults.sort(compLegTime);
      addPositions(sortedResults, 'positionLegTime', 'compLegTime'); 
      //
      // For each control STEP 4: Store calculation results
      sortedResults.forEach(result => {
        if (!statusManual(result)) {
          storePositionWithControl(result, ctrlIndex);
        }
      });
    }
  }
}

export function calculateControlPuistopositions(filteredResults, controls) {
  if (filteredResults.length) {
    let sortedResults = [...filteredResults]; // separate array, same objects

    for (let ctrlIndex = 0; ctrlIndex < controls.length; ctrlIndex++) {
      //
      // For each control STEP 1: prepare the two sorts
      sortedResults.forEach(result => {
        if (!statusManual(result)) {
          decorateWithControl(result, ctrlIndex);
        }
      });
      // For each control STEP 2: Sort and position calc cumTime.
      sortedResults.sort(compCumTime);
      addPositions(sortedResults, 'positionCumTime', 'compCumTime');
      //
      // For each control STEP 3: Sort and position calc legTime.
      sortedResults.sort(compLegTime);
      addPositions(sortedResults, 'positionLegTime', 'compLegTime'); 
      //
      // For each control STEP 4: Store calculation results
      sortedResults.forEach(result => {
        if (!statusManual(result)) {
          storePuistopositionWithControl(result, ctrlIndex);
        }
      });
    }
  }
}

function compTime(a, b) {
  const aComp = !statusOk(a) && !statusManual(a) ? LONGTIME : a.time;
  const bComp = !statusOk(b) && !statusManual(b) ? LONGTIME : b.time;
  return aComp - bComp;
}

function compCumTime(a, b) {
  const aComp = !statusManual(a) && a.compCumTime ? a.compCumTime : LONGTIME;
  const bComp = !statusManual(b) && b.compCumTime ? b.compCumTime : LONGTIME;
  return aComp - bComp;
}

function compLegTime(a, b) {
  const aComp = !statusManual(a) && a.compLegTime ? a.compLegTime : LONGTIME;
  const bComp = !statusManual(b) && b.compLegTime ? b.compLegTime : LONGTIME;
  return aComp - bComp;
}

function addPositions(results, nameOfPosition, nameOfTime) {
  let prevTime = 0;
  // 'position' can be position, positionCumTime or positionLegTime, passed in
  // 'nameOfPosition'. positionCumTime and positionLegTime are temporary
  // storages for control positions.
  let position = 0;
  // 'results' is already properly sorted with the right sortFunc
  results.forEach((result, i) => {
    // Three possibilities
    // CASE 1: Position none, something wrong with the result
    if (isSomethingWrongWithResult(result, nameOfTime)) {
      position = null;
    // CASE 2: Base case, converting 0-based i to 1-based position
    } else if (result[nameOfTime] > prevTime) {
      position = i + 1;
    }
    // CASE 3: No else clause needed: if time not > prevTime
    // then position same as with previous result (never < 0).
    result[nameOfPosition] = position;
    // Needed for CASE 2 comparison in next iteration
    prevTime = result[nameOfTime];
  });
}

function isSomethingWrongWithResult(result, nameOfTime) {
  if (nameOfTime === 'time') {
    return !statusOk(result) && !statusManual(result);
  } else {
    return statusManual(result) || !result[nameOfTime];
  }
}

function decorateWithControl(result, ctrlIndex) {
  // Function used in preparation for sort
  if (result.controlTimes) {
    result.compCumTime = result.controlTimes[ctrlIndex].time;
    result.compLegTime = result.controlTimes[ctrlIndex].legTime;
  } else {
    result.compCumTime = LONGTIME;
    result.compLegTime = LONGTIME;
  }
}

function storePositionWithControl(result, ctrlIndex) {
  // Function used in storing calculated positions in-place.
  if (result.controlTimes) {
    result.controlTimes[ctrlIndex].position = result.positionCumTime;
    result.controlTimes[ctrlIndex].legPosition = result.positionLegTime;
  }
}

function storePuistopositionWithControl(result, ctrlIndex) {
  // Function used in storing calculated positions in-place.
  if (result.controlTimes) {
    result.controlTimes[ctrlIndex].puistoposition = result.positionCumTime;
    result.controlTimes[ctrlIndex].legPuistoposition = result.positionLegTime;
  }
}
