import React from "react";
import './App.css';
import Header from './Header';
import useNavisportRaceList from './useNavisportRaceList';
import NimenhuutoEventList from './NimenhuutoEventList';
import DataPuistoRaceList from './DataPuistoRaceList';
import NavisportRaceList from './NavisportRaceList';
import RaceOnline from './RaceOnline';
import PuistoWebStoryList from './PuistoWebStoryList';
import PuistoKartatList from './PuistoKartatList';
import DataPuistoStravaStats from './DataPuistoStravaStats';

export default function App() {
  // navisportRaceList state is on the top level to reduce reads
  const navisportRaceList = useNavisportRaceList();
  return (
    <div className="App">

      <Header />

      <div className="App-container">

        <div className="App-column">
          <div className="App-frame">
            <NimenhuutoEventList noOfRows={2} />
          </div>
          <div className="App-frame">
            <DataPuistoRaceList noOfRows={2} />
          </div>
          <div className="App-frame">
            <NavisportRaceList raceList={navisportRaceList} noOfRows={2} />
          </div>
          <div className="App-frame">
            <RaceOnline raceList={navisportRaceList} />
          </div>
        </div>

        <div className="App-column">
          <div className="App-frame">
            <PuistoWebStoryList noOfRows={2} />
          </div>
          <div className="App-frame">
            <PuistoKartatList noOfRows={2} />
          </div>
          <div className="App-frame">
            <DataPuistoStravaStats />
          </div>
        </div>
      </div>
    </div>
  );
}

