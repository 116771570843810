import React from "react";
import './Header.css';

export default function Header() {
  return (
    <header className="App-header">
      <img className="App-header-icon" src="OC_black.svg" alt="OC Puisto" />
      <span className="App-header-brand">Puisto-Online</span>
      <a href="https://www.ocpuisto.net" className="navbar-item">PuistoWeb</a>
      <a href="https://datapuisto.ocpuisto.net" className="navbar-item">DataPuisto</a>
      <a href="https://kartat.ocpuisto.net" className="navbar-item">PuistoKartat</a>
    </header>
  );
}
