// This module calculates time stats for each result of a course
// by Riku on 2020-11-21
//
import { statusManual } from './definitions';

export function addTotalTimeAndLegTimes(results) {
  //ensures that every result has a time and calculates leg times.
  results.forEach(result => {
    if (result.controlTimes) {
      if (!('time' in result) || !result.time) {
        result.time = result.controlTimes[result.controlTimes.length - 1].time
      }
      if (!statusManual(result)) {
        let previousTime = 0;
        result.controlTimes.forEach(visit => {
          if (visit.time) {
            visit.legTime = visit.time - previousTime;
            previousTime = visit.time;
          } else {
            visit.legTime = null;
          }
        });
      }
    }
  });
}

// export function addTimeStats(course, results, isPuisto) {
//   addControlStatsForVar(course.controls, results, 'time', isPuisto);
//   addControlStatsForVar(course.controls, results, 'legTime', isPuisto);
//   addCourseMeanAndMinTime(course, results, isPuisto);
// }

/*
 *  *****************************************************************
 */

// function addControlStatsForVar(controls, results, nameOfVar, isPuisto) {
//   const prefix = nameOfVar === 'time' ? '' : 'leg_';
//   const nae
//     prefix = '';
//   } else if {
//     prefix = 
//   }
// }
//
// *************
// Jäänyt kesken
// *************
