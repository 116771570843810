import React, { useState } from "react";
import PropTypes from 'prop-types';
import RaceHeader from './RaceHeader';
import Course from './Course';
import useMatchNames from './useMatchNames';
import useCourseList from './useCourseList';
import useResultLongList from './useResultLongList';
import useProcessedResults from './useProcessedResults';

export default function Race(props) {
  const [isDefault, setIsDefault] = useState(!props.wasChanged);

  const matchNames = useMatchNames();
  const courseList = useCourseList(props.race);
  const resultLongList = useResultLongList(props.race);

  const [courseIndex, setCourseIndex] = useState(props.race.initCourseIndex || 0);
  const handleChange = ({ target }) => {
    setCourseIndex(target.value);
  }

  const processedResults = useProcessedResults(matchNames, resultLongList, courseList, courseIndex, isDefault);

  if (!courseList.length) {
    return <br />;
  } else if (isDefault) {
    // This is usually the very first render, triggers Effect 
    setIsDefault(false);
    return <br />;
  } else {
    return (
      <div>
        <select className='dropdown' defaultValue={courseIndex} onChange={handleChange}>
          {courseList.map((course, i) => 
            <option key={i} value={i}>
                {course.name}
            </option>
          )}
        </select>
        <RaceHeader race={props.race} />
        <Course 
          course={courseList[courseIndex]}
          results={processedResults}
        />
      </div>
    );
  }
}

Race.propTypes = {
  race: PropTypes.object.isRequired,
  wasChanged: PropTypes.bool.isRequired,
}
