// Course shows the chosen (or default course) and its processed results.
// by Riku on 2020-11-20
//
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ResultList from './ResultList';

export default function Course(props) {

  const [nameOfPosition, setNameOfPosition] = useState('puistoposition');
  const handleChange = ({ target }) => {
    setNameOfPosition(target.value);
  }

  return (
    <div>
      <div className='flex-container-course'>
        <div className='action-column'>
          <h5>{props.course.name} {props.course.distance}</h5>
        </div>
        <div className='action-column'>
          <select 
            className='dropdown' 
            defaultValue='puistoposition' 
            onChange={handleChange}
          >
            <option key='control' value='puistoposition'>
              Rasti
            </option>
            <option key='visit' value='legPuistoposition'>
              Rastiväli
            </option>
          </select>
        </div>
      </div>
      <ResultList results={props.results} nameOfPosition={nameOfPosition} />
    </div>
  );
}

Course.propTypes = {
  course: PropTypes.object.isRequired,
  results: PropTypes.array.isRequired,
}
