// Pure JavaScript processing of analyzed Results, copied from analyzer.py
// by Riku on 2020-11-21
//

import { cleanResults } from './resultsCleaner';
//import { addTotalTimeAndLegTimes, addTimeStats } from './timeStats';
import { addTotalTimeAndLegTimes } from './timeStats';
import { 
  calculateOverallPositions, 
  calculateControlPositions,
  calculateControlPuistopositions } from './positionStats';
// import { addPuistomanStats } from './puistomanStats';

export default function getAnalyzedResults(results, course, matchNames) {
  if (course.controls.length < 2) {
    return [];
  }
  const courseResults = results.filter(
    result => result.courseId === course.id
  );
  cleanResults(courseResults, course.controls);
  
  addTotalTimeAndLegTimes(courseResults);
  calculateControlPositions(courseResults, course.controls);
  // addTimeStats(course, courseResults, false);
  calculateOverallPositions(courseResults, 'position');

  const filteredResults = courseResults.filter(
    result => matchNames.includes(result.name));

  calculateOverallPositions(filteredResults, 'puistoposition');
  calculateControlPuistopositions(filteredResults, course.controls);
  // addTimeStats(course, filteredResults, true);
  // addPuistomanStats(course, filteredResults);

  return filteredResults;
}
