import { useState, useEffect } from "react";

export default function useCourseList(race) {
  const [list, setList] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_NAVISPORT_API_URL}/events/${race.id}/courses`)
    .then(response => response.json())
    .then(courseList => setList(courseList))
  }, [race.id]);

  return list;
}
