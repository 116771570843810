import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import * as rssParser from 'react-native-rss-parser';
import ThreeColTable from './ThreeColTable';
// import './App.css';

export default function PuistoKartatList(props) {
  const [rssFeed, setRrsFeed] = useState({});

  useEffect(() => { 
    fetch(process.env.REACT_APP_PUISTOKARTAT_FEED_URL)
      .then((response) => response.text())
      .then((responseData) => rssParser.parse(responseData))
      .then((rss) => setRrsFeed({rss}))
  },[]);

  if (!rssFeed.rss) {
    return <br />;
  } else {
    return <div>
      <h3>PuistoKartat</h3>
      <ThreeColTable
        items={rssFeed.rss.items.slice(0, props.noOfRows).map(item =>
          [
            item.description.split("<br />")[0],
            item.links[0].url,
            item.title,
            new Date(item.published).toISOString().slice(0, 10),
          ]
        )}
      />
    </div>
  }
}

PuistoKartatList.propTypes = {
  noOfRows: PropTypes.number.isRequired,
}
