import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import ThreeColTable from './ThreeColTable';
// import './App.css';

export default function NimenhuutoEventList(props) {
  const [feed, setFeed] = useState([]);

  useEffect(() => {
    fetch(process.env.REACT_APP_MY_NIMENHUUTO_SERVICE_URL)
      .then(response => response.json())
      .then(eventsList => setFeed(eventsList))
  },[]);

  // The summary-field always starts with 'ocpuisto: '. Therefore slice from 10
  const stripCalendarName = summaryStr => summaryStr.slice(10);

  if (!feed.length) {
    return <br />;
  } else {
    return <div>
      <h3>Nimenhuuto</h3>
      <ThreeColTable
        items={feed.slice(0, props.noOfRows).map(item =>
          [
            item.categories[0],
            item.url,
            stripCalendarName(item.summary),
            item.start.slice(0, 10),
          ]
        )}
      />
    </div>
  }
}

NimenhuutoEventList.propTypes = {
  noOfRows: PropTypes.number.isRequired,
}
