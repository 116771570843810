// Mimicking the behavior of results_cleaner.py
// By Riku 2020-11-21
//
import { statusManual, LONGTIME } from './definitions';

export function cleanResults(results, controls) {
  // add ordernumbers to course controls
  controls.forEach((cc, i) => { cc.ordernumber = i; });

  results
    .filter(result => !statusManual(result))
    .filter(result => result.controlTimes)
    .forEach(result => cleanResultV2(result, controls));
}
/*
 *
 */

function isMatchingCode(visitCode, controlCode) {
  return Array.isArray(controlCode)
    ? controlCode.includes(visitCode) 
    : controlCode === visitCode;
}

function createVisit(control, code, time) {
  return {
    control,
    ordernumber: control.ordernumber,
    code,
    time,
  };
}

function createMissedVisit(expectedControl) {
  return createVisit(expectedControl, expectedControl.code, null);
}

function getFilteredVisitList(allVisitsIndex, controls, isAscending) {
  let empties = 0;
  let allVisitsIndex2 = [];
  if (isAscending) {
    allVisitsIndex.reduce((earliest, allVisits, i) => {
      let newEarliest = earliest;
      // Filters out too early visits - cannot regard those visits as valid
      const controlVisitsList = allVisits.filter(v => v >= earliest);
      if (controlVisitsList.length === 0) {
        empties++;
      } else { 
        // visit did happen, so next visit must be later
        newEarliest = Math.min(...controlVisitsList) + 1;
      }
      allVisitsIndex2.push(controlVisitsList);
      return newEarliest;
    }, 0)
  } else {
    const revAllVisitsIndex = allVisitsIndex.slice().reverse(); // temporary reversed array
    revAllVisitsIndex.reduce((latest, allVisits, i) => {
      let newLatest = latest;
      // Filters out too late visits - cannot regard those visits as valid
      const controlVisitsList = allVisitsIndex.filter(v => v <= latest);
      if (controlVisitsList.length === 0) {
        empties++;
      } else { 
        // visit did happen, so previous visit must be earlier
        newLatest = Math.max(...controlVisitsList) - 1;
      }
      allVisitsIndex2.push(controlVisitsList);
      return newLatest;
    }, 1000) // arbitrarily big number
    allVisitsIndex2.reverse();
  }
  return [allVisitsIndex2, empties];
}

function cleanResultV2(result, controls) {
  let allVisits = result.controlTimes;
  const lastControlCode = controls[controls.length - 1].code;

  // Step 0. Make sure there is always a time on finish.
  // A LONGTIME entry will eventually be written as a 'missed visit'
  if (allVisits.every(v => !isMatchingCode(v.code, lastControlCode))) {
  //if (allVisits.every(v => v.code !== lastControlCode)) {
    allVisits.push({
      code: Array.isArray(lastControlCode) ? lastControlCode[0] : lastControlCode,
      time: LONGTIME
    });
  }
  // Step1. Create a list of all possible visits
  let allVisitsIndex = [];
  for (let i = 0; i < controls.length; i++) {
    const code = controls[i].code;
    const controlVisitsList = 
      allVisits
      .map((visit, index) => [visit, index])
      .filter(v => isMatchingCode(v[0].code, code))
      //.filter(v => v[0].code === code)
      .map(v => v[1]);
    allVisitsIndex.push(controlVisitsList);
  }
  // Step 2. Study from the start - filter out those visits that were
  // too early in order
  const step2Out = getFilteredVisitList(allVisitsIndex, controls, true);

  // Step 3. Study from the end - filter out those visits that were
  // too late in order
  const step3Out = getFilteredVisitList(allVisitsIndex, controls, false);

  // Step 4. Select either Step 2 outcome or Step 3, whichever is a better
  // match to the track. The second element of the arrays is 'empties'.
  if (step2Out[1] <= step3Out[1]) {
    allVisitsIndex = step2Out[0];
  } else {
    // Filter out too early visits to ensure that the logic of
    // min-function in Step 5. makes right choices
    const step4Out = getFilteredVisitList(step3Out[0], controls, true);
    allVisitsIndex = step4Out[0];
  }
  // Step 5. Create the clean list of visits based on the filtered
  // visit index
  let cleanEntries = [];
  for (let i = 0; i < allVisitsIndex.length; i++) {
    let entry;
    // there can be zero, one, or several possible visits for each control
    if (allVisitsIndex[i].length > 0) {
      const j = Math.min(...allVisitsIndex[i]);
      const visit = allVisits[j];
      entry = createVisit(controls[i], visit.code, visit.time);
    } else {
      entry = createMissedVisit(controls[i]);
    }
    cleanEntries.push(entry);
  }
  // Step 6. Save the result to right place
  result.controlTimes = cleanEntries;
}
