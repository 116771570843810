import { useState, useEffect } from "react";

// eslint-disable-next-line
function filterList(longList) {
  // JavaScript measures time in milliseconds. We don't care about daylight
  // saving time here.
  // const threeDays = 3 * 24 * 60 * 60 * 1000;
  const oneDay =  24 * 60 * 60 * 1000;
  const shortList = longList.filter(
      (item, index) => 
        Date.parse(item.begin) > Date.now() - oneDay * 1 &&
        Date.parse(item.begin) < Date.now() + oneDay * 1
    )
  //const dateList = shortList.map(item => item.begin)
  //const isNotDuplicate = (item, index) => dateList.indexOf(item.begin) === index
  //console.log('before filtering, dateList: ' + JSON.stringify(dateList));
  //const shorterList = shortList.filter(isNotDuplicate);
  //console.log('after filtering, length: ' + shorterList.length);

  //return shortList.filter(isNotDuplicate)
  return shortList
}

// This was used 2021-summer, now old
export default function useNavisportRaceList() {
  const [feed, setFeed] = useState([]);

  useEffect(() => {
    // This was used 2021-summer, now old
    // fetch(process.env.REACT_APP_NAVISPORT_EVENTS_URL)
    fetch(process.env.REACT_APP_MY_NAVISPORT_CUSTOM_URL)
      .then(response => response.json())
      // .then(longList => filterList(longList))
      .then(shortList => setFeed(shortList))
  },[]);

  return feed;
}
