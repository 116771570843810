import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import ThreeColTable from './ThreeColTable';
// import './App.css';

export default function DataPuistoRaceList(props) {
  const [feed, setFeed] = useState([]);

  useEffect(() => {
    fetch(process.env.REACT_APP_DATAPUISTO_RACESFEED_URL)
      .then(response => response.json())
      .then(eventsList => setFeed(eventsList))
  },[]);

  if (!feed.length) {
    return <br />;
  } else {
    return <div>
      <h3>DataPuisto</h3>
      <ThreeColTable
        items={feed.slice(0, props.noOfRows).map(item =>
          [
            item.serie,
            item.url,
            item.name,
            item.date,
          ]
        )}
      />
    </div>
  }
}

DataPuistoRaceList.propTypes = {
  noOfRows: PropTypes.number.isRequired,
}

