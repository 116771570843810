// Result renders a Result component as a table row
// by Riku on 2020-11-22
//
import React from 'react';
import PropTypes from 'prop-types';
import { timeStr } from './strFormat';
//import CumPosString from './CumPosString';

export default function Result(props) {
  return (
    <tr key={props.result.id}>
      <td className='main' key='puistoposition'>
        {props.result.puistoposition}.
      </td>
      <td className='main' key='position'>
        {props.result.position}.
      </td>
      <td className='main name' key='name'>
        {props.result.name}
      </td>
      <td className='main time strong' key='time'>
        {timeStr(props.result.time)}
      </td>

      {props.result.controlTimes ?
         props.result.controlTimes.map(visit =>
          <td 
            className={
              `detail${visit[props.nameOfPosition] === 1 
                ? ' best_1' 
                : visit[props.nameOfPosition] === 2 
                  ? ' best_2'
                  : visit[props.nameOfPosition] === 3
                    ? ' best_3'
                    : '' }`} 
            key={visit.ordernumber}>
            {visit[props.nameOfPosition] ? visit[props.nameOfPosition] : '-'}
          </td>
         )
         : <td className='' key='1'></td>}
    </tr>
  );
}

Result.propTypes = {
  result: PropTypes.object.isRequired,
  nameOfPosition: PropTypes.string.isRequired,
}
