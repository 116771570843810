import React from "react";
import PropTypes from 'prop-types';
import './ThreeColTable.css';

export default function ThreeColTable(props) {
  return <table> 
    <colgroup>
      <col className="Col1" />
      <col className="Col2" />
      <col className="Col3" />
    </colgroup>
    <tbody>
      {props.items.map((item, index) =>
      <tr key={index}>
        <td>{item[0]}</td>
        <td><a href={item[1]}>{item[2]}</a></td>
        <td>{item[3]}</td>
      </tr>
      )}
    </tbody>
  </table>;
}

ThreeColTable.propTypes = {
  items: PropTypes.array.isRequired,
}
