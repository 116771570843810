// ResultList lays out the table for showing the results
// by Riku on 2020-11-21
//
import React from 'react';
import PropTypes from 'prop-types';
import Result from './Result';

export default function ResultList(props) {
  if (!props.results.length) {
    return <br />;
  } else {
    return (
      <table className='results'>
        <thead>
          <tr>
            {['Puisto-sija', 'Sija', 'Nimi', 'Aika'].map(column => 
              <th className='main' key={column}>
                {column}
              </th>
            )}
            {props.results[0].controlTimes.map(visit => 
              <th className='detail' key={visit.ordernumber}>
                {visit.ordernumber + 1} <br />
                {visit.code}
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {props.results
              .map(result => 
                <Result 
                  key={result.id} 
                  result={result} 
                  nameOfPosition={props.nameOfPosition} 
                />)
          }
        </tbody>
      </table>
    );
  }
}

ResultList.propTypes = {
  results: PropTypes.array.isRequired,
  nameOfPosition: PropTypes.string.isRequired,
}
