import React from "react";
import PropTypes from 'prop-types';
import { hoursStr, inKm } from './strFormat';

export default function BarGroup(props) {
  const xTime = props.x + 10;
  const xDist = (props.x / 2) + 103;
  const maxBarWidth = 90 - (props.x / 2);
  const widthTime = maxBarWidth * (props.data.sum_elapsed_time / props.timeMax);
  const widthDist = maxBarWidth * (props.data.sum_distance / props.distMax);
  const barHeight = 4;
  const barPadding = 0.5;
  const yText = props.y + 2.2;

  return (
  <g className="bar-group">
    <text 
      className="sport-label"
      x={props.x}
      y={yText}
      alignmentBaseline="middle"
    >
      {props.data.type.slice(0, 4)}
    </text>
    <rect
      className={props.data.type}
      x={xTime}
      y={props.y + barPadding * 0.5}
      width={widthTime}
      height={barHeight - barPadding}
    />
    <text 
      className={widthTime > 12 ? "value-label-in" : "value-label-out"}
      x={widthTime > 12 ? xTime + (0.95 * widthTime) - 8 : xTime + (0.95 * widthTime) + 2 }
      y={yText}
      alignmentBaseline="middle"
    >
      {hoursStr(props.data.sum_elapsed_time)}
    </text>
    <rect
      className={props.data.type}
      x={xDist}
      y={props.y + barPadding * 0.5}
      width={widthDist}
      height={barHeight - barPadding}
    />
    <text 
      className={widthDist > 14 ? "value-label-in" : "value-label-out"}
      x={widthDist > 14 ? xDist + (0.95 * widthDist) - 13 : xDist + (0.95 * widthDist) + 3}
      y={yText}
      alignmentBaseline="middle"
    >
      {props.data.sum_distance > 0 ? inKm(props.data.sum_distance) : ""}
    </text>
  </g>
  );
}

BarGroup.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired,
  timeMax: PropTypes.number.isRequired,
  distMax: PropTypes.number.isRequired,
}
