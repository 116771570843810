function pad2(num) {
  return num.toString().padStart(2, '0');
}

export function timeStr(value) {
  try {
    const timeSeconds = Math.floor(value);
    const sign = (timeSeconds >= 0) ? "" : "-";
    let mins = Math.floor(timeSeconds / 60);
    const secs = timeSeconds % 60;
    if (mins >= 60) {
      const hours = Math.floor(mins / 60);
      mins = mins % 60;
      return `${sign}${hours}:${pad2(mins)}:${pad2(secs)}`;
    } else {
      return `${sign}${mins}:${pad2(secs)}`;
    }
  }     
  catch(err) {
    alert(err);
  }
}

export function hoursStr(value) {
  try {
    const s = pad2(Math.floor((value + 180) / 360).toString());
    return `${s.slice(0, -1)},${s.slice(-1)} h`
  }     
  catch(err) {
    alert(err);
  }
}

export function inKm(value) {
  try {
    const metres = Math.floor(value) + 50; // 50 needed for proper rounding
    const km = Math.floor(metres / 1000);
    const m = metres % 1000;
    const decim = Math.floor(m / 100);
    return `${km},${decim} km`;
  }     
  catch(err) {
    alert(err);
  }
}

