import { useState, useEffect } from "react";

export default function useMatchNames() {
  const [list, setList] = useState([]);

  useEffect(() => {
    fetch(process.env.REACT_APP_DATAPUISTO_MATCHNAMES_URL)
      .then(response => response.json())
      .then(nameList => setList(nameList))
  },[]);

  return list;
}
