import React, { useState, useEffect } from "react";
//import { timeStr, inKm } from './strFormat';
import FullChart from './FullChart';
import './DataPuistoStravaStats.css';

export default function DataPuistoStravaStats() {
  const [feed, setFeed] = useState([]);

  useEffect(() => {
    fetch(process.env.REACT_APP_DATAPUISTO_STRAVAFEED_URL)
      .then(response => response.json())
      .then(eventsList => {
        eventsList.reverse();
        setFeed(eventsList);
      })
  },[]);


  if (!feed.length) {
    return <br />;
  } else {
    return <div>
      <h3>Strava-tilastot</h3>
      <FullChart stravaData={feed} />
    </div>;
  }
}

  //if (!feed.length) {
    //return <br />;
  //} else {
    ////return <pre>{JSON.stringify(feed, null, 2)}</pre>;
    //return (
      //<div>
        //<h3>Puiston Strava</h3>
        //<table> 
          //<colgroup>
            //<col className="Col-a" />
            //<col className="Col-b" />
            //<col className="Col-c" />
            //<col className="Col-d" />
          //</colgroup>
          //<thead>
            //<th>Date</th>
            //<th>Sport</th>
            //<th>Elapsed time</th>
            //<th>Distance</th>
          //</thead>
          //<tbody>
            //{feed.map(dateItem =>
              //dateItem[1].map((sportItem, index) => 
              //<tr key={sportItem.date + sportItem.type}>
                //{index === 0 ? <td className="topRow" rowspan={dateItem[1].length}>{sportItem.date}</td> : 
                  //<td>{sportItem.type}</td>}
                //{index === 0 ? <td className="topRow">{sportItem.type}</td> :
                  //<td>{timeStr(sportItem.sum_elapsed_time)}</td>}
                //{index === 0 ? <td className="topRow">{timeStr(sportItem.sum_elapsed_time)} </td> : 
                  //<td>{inKm(sportItem.sum_distance)}</td>}
                //{index === 0 ? <td className="topRow">{inKm(sportItem.sum_distance)}</td> : 
                  //<td></td>}
              //</tr>
              //)
            //)}
          //</tbody>
        //</table>
      //</div>
    //);
  //}
//}

