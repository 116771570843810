// RaceHeader prints out the Race header info
// by Riku on 2020-11-20
//
import React from 'react';
import PropTypes from 'prop-types';

export default function RaceHeader(props) {
  return (
    <div>
      <h4>{props.race.series.name} - {props.race.name} ({props.race.begin.slice(0, 10)})</h4>
    </div>
  );
}

RaceHeader.propTypes = {
  race: PropTypes.object.isRequired,
}
