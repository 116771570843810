import React from "react";
import PropTypes from 'prop-types';
import ThreeColTable from './ThreeColTable';
//import './App.css';
//import NavisportRaceItem from './NavisportRaceItem';

export default function NavisportRaceList(props) {
  const base_url = process.env.REACT_APP_NAVISPORT_EVENTS_BASE_URL;

  if (!props.raceList.length) {
    return <br />;
  } else {
    return <div>
      <h3>Navisport-tulokset</h3>
      <ThreeColTable
        items={props.raceList.slice(0, props.noOfRows).map(item =>
          [
            item.series.name,
            base_url + item.id,
            item.name,
            item.begin.slice(0, 10),
          ]
        )}
      />
    </div>
  }
}

NavisportRaceList.propTypes = {
  raceList: PropTypes.array.isRequired,
  noOfRows: PropTypes.number.isRequired,
}
