import React from "react";
import PropTypes from 'prop-types';
import BarChart from './BarChart';
import './FullChart.css';

export default function FullChart(props) {
  const firstBarChartStart = -48;
  const barChartHeight = 16;

  let flatArray = [];
  props.stravaData.forEach(dayArray => flatArray.push(...dayArray[1]));
  const timeMax = Math.max(...flatArray.map(item => item.sum_elapsed_time)); 
  const distMax = Math.max(...flatArray.map(item => item.sum_distance)); 

  return <svg className="full-chart" viewBox="0 0 200 120">
    {props.stravaData.map((dayData, index) => <BarChart 
      key={dayData[0]}
      yStart={firstBarChartStart + index * barChartHeight}
      dayData={dayData}
      timeMax={timeMax}
      distMax={distMax}
    />
    )}
    </svg>
}

FullChart.propTypes = {
  stravaData: PropTypes.array.isRequired,
}
