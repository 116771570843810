import React from "react";
import PropTypes from 'prop-types';
import BarGroup from './BarGroup';

export default function BarChart(props) {
  const firstBarStart = (4 - props.dayData[1].length) * 2;
  const barXStart = 24;
  const barHeight = 4;

  return <svg className="bar-chart" viewBox={`0 ${props.yStart} 200 16`}>
      <text className="chart-date" x="1" y="9">
        {props.dayData[0]}
      </text>
    {props.dayData[1].map((sportData, index) => <BarGroup
      key={sportData.date + sportData.type}
      x={barXStart}
      y={firstBarStart + index * barHeight}
      data={sportData}
      timeMax={props.timeMax}
      distMax={props.distMax}
    />
    )}
  </svg>
}

BarChart.propTypes = {
  yStart: PropTypes.number.isRequired,
  dayData: PropTypes.array.isRequired,
  timeMax: PropTypes.number.isRequired,
  distMax: PropTypes.number.isRequired,
}


//export default function BarChart(props) {
  //const barHeight = 30;
  //const barGroups = data.map((d, i) => return (
    //<g transform={`translate(0, ${i * barHeight})`}>
      //<BarGroup d={d} barHeight={barHeight} barColor={'#348AA7'} />
    //</g>
  //);
  //)

  //return <svg className="Bar-chart" viewBox={`0 ${props.yStart} 200 80`}>
    //<g className="container">
      //<text className="title" x="10" y="30">Tähän tulee otsikko</text>
      //<g className="chart" transform="translate(100, 60)">
        //{barGroups}
      //</g>
    //</g>
  //</svg>
//}


