import { useState, useEffect } from "react";

export default function useResultLongList(race) {
  const [list, setList] = useState([]);

  useEffect(() => {

    const handleResultsUpdate = () => {
      fetch(`${process.env.REACT_APP_NAVISPORT_API_URL}/events/${race.id}/results`)
        .then(response => response.json())
        .then(resultList => setList(resultList))
    }

    handleResultsUpdate();
    const interval = setInterval(() => {
      handleResultsUpdate();
    }, 60 * 1000 /* Every 60 seconds */);
    return () => clearInterval(interval);
  }, [race.id]);

  return list;

}
